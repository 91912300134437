
import AddUtils from "./AddUtils.js";
import StringUtils  from '@/utils/StringUtils.js';
import ConstUtils  from '@/utils/ConstUtils.js';

import Checklist from '@/domain/model/checklist/CheckList.js';
import ChecklistItem from '@/domain/model/checklist/item/CheckListItem.js';
import ChecklistItemOption from '@/domain/model/checklist/item/option/CheckListItemOption.js';
import ChecklistItemOptionMap from '@/domain/model/checklist/item/option/CheckListItemOptionMap.js';

export default class AddModel {
  
  constructor(panel, checklist) {
    this._panel = panel;
    this._checklist = checklist;
    this._tableData = [];
    this._domain = this.panel().domain;
    this._isSaving = false;
    this._showMissingRequirements = false;
    this._error = null;
  }
  
  start() {
    this._checklist.populate();
    this.populateData();
  }
  
  populateData() {
    this._tableData = [];
    var newData = []
    this.buildRowData(newData)
    this.domain().logger().info("Populate Data - Checklist: {0}", [this.checklist().toString()]);
//    this.domain().logger().info("Structure: {0}", [JSON.stringify(newData, null, 1)]);
    this._tableData = newData;
  }
  
  buildRowData(newData) {
    var au = new AddUtils(this.domain(), this.panel(), newData);
    au.start();
  }
  
  domain() {
    return this._domain;
  }
  
  panel() {
    return this._panel;
  }
  
  checklist() {
    return this._checklist;
  }
  
  withValue(kvp) {
    this._showMissingRequirements = false;
    if (kvp) {
      this.domain().logger().info("Received: {0}", [JSON.stringify(kvp)]);
      
      var objectName = kvp['object'];
      var field = kvp['field'];
      var fieldValue = kvp['value'];
      var objectId = kvp['id'];
      
      if (objectName === Checklist.MODEL_NAME) {
        if (field === Checklist.Fields.Name) {
          this.checklist().withName(fieldValue);
        }
        if (field === Checklist.Fields.Description) {
          this.checklist().withDescription(fieldValue);
        }
      }
      if (objectName === ChecklistItem.MODEL_NAME) {
        var item = this.checklist().items().getChecklistItem(objectId);
        if (field === ChecklistItem.Fields.Name) {
          item.withName(fieldValue);
        }
        
        if (field == ChecklistItem.Fields.Type) {
          item.withType(fieldValue);
          if (item.isTypeBool()) {
            this.doChecklistItemTypeBool(item, kvp);
          } else if (item.isTypeList()) {
            this.doChecklistItemTypeList(item, kvp);
          } else {
            var optionMap = new ChecklistItemOptionMap(this.domain());
            item.withOptions(optionMap);
            this.panel().populateData();
          }
        }
        
        if (field == ChecklistItem.Fields.MultiSelect) {
          item.withMultiSelect(kvp.value);
        }
      }
    }
    if (objectName === ChecklistItemOption.MODEL_NAME) {
      this.doChecklistItemOptionName(kvp);
    }
    
//    this.domain().logger().info("WithValue - Checklist: {0}", [this.checklist().toString()]);
  }
  
  doChecklistItemTypeBool(item, kvp) {
    if (!kvp) {
      return;
    }
    
    var optionMap = item.options();
    if (optionMap.isEmpty()) {
      var yesOption = new ChecklistItemOption(this.domain());
      yesOption
        .withIndex(optionMap.size())
        .withName("Yes")
        .withCategoryGood()
      optionMap.add(yesOption);

      var noOption = new ChecklistItemOption(this.domain());
      noOption
        .withIndex(optionMap.size())
        .withName("No")
        .withCategoryMinor()
        optionMap.add(noOption);
    }
    
    item.withOptions(optionMap);
    this.panel().populateData();
  }
  
  doChecklistItemTypeList(item, kvp) {
    if (!kvp) {
      return;
    }
    var optionMap = item.options();
    if (optionMap.isEmpty()) {
      var option = new ChecklistItemOption(this.domain());
      option.withCategoryGood();
      option.withIndex(optionMap.size());
      optionMap.add(option);
    }
    item.withOptions(optionMap);
    this.panel().populateData();
  }
  
  doChecklistItemOptionName(kvp) {
    var parentId = kvp['parentId'];
    var field = kvp['field'];
    var fieldValue = kvp['value'];
    var objectId = kvp['id'];
    var items = this.checklist().items();
    var checklistItem = items.getChecklistItem(parentId);
    var options = checklistItem.options();
    var itemOption = options.getCheckListItemOption(objectId);
    
    if (field === ChecklistItemOption.Fields.Name) {
      itemOption.withName(fieldValue);
    }
    if (field === ChecklistItemOption.Fields.Category) {
      itemOption.withCategory(fieldValue);
    }
    
  }
  
  withButtonPressed(buttonData) {
    if (!buttonData) {
      return;
    }
    this.domain().logger().info("Received: {0}", [JSON.stringify(buttonData)]);
    var field = buttonData['field'];
    
    if (field === AddUtils.Fields.AddQuestionButton) {
      this.doAddChecklistItemAction(buttonData);
    }
    if (field === AddUtils.Fields.RemoveQuestionButton) {
      this.doRemoveChecklistItemAction(buttonData);
    }
    if (field === AddUtils.Fields.UpQuestionButton) {
      this.doUpChecklistItemAction(buttonData);
    }
    if (field === AddUtils.Fields.DownQuestionButton) {
      this.doDownChecklistItemAction(buttonData);
    }

    if (field === AddUtils.Fields.UpOptionButton) {
      this.doUpOptionAction(buttonData);
    }
    if (field === AddUtils.Fields.DownOptionButton) {
      this.doDownOptionAction(buttonData);
    }
    if (field === AddUtils.Fields.AddOptionButton) {
      this.doAddOptionAction(buttonData);
    }
    if (field === AddUtils.Fields.RemoveOptionButton) {
      this.doRemoveOptionAction(buttonData);
    }
    if (field === AddUtils.Fields.SaveButton) {
      this.doSaveAction();
    }
    if (field === AddUtils.Fields.CancelButton) {
      this.doCancelAction();
    }
//    this.domain().logger().info("With Button Pressed - Checklist: {0}", [this.checklist().toString()]);
  }
  
  doUpChecklistItemAction(buttonData) {
    if (!buttonData) {
      return;
    }
    

    var objectId = buttonData['id'];
    var itemMap = this.checklist().items();
    var item = itemMap.getChecklistItem(objectId);
    var nextItem = itemMap.getChecklistItemByIndex(item.index() - 1);
    
    nextItem.withIndex(item.index());
    item.withIndex(item.index() - 1);
    
    itemMap.add(item);
    itemMap.add(nextItem);
    this.checklist().withItems(itemMap);

    this.panel().populateData();
    
  }
  
  doDownChecklistItemAction(buttonData) {
    if (!buttonData) {
      return;
    }
    
    var objectId = buttonData['id'];
    var itemMap = this.checklist().items();

    var item = itemMap.getChecklistItem(objectId);
    var nextItem = itemMap.getChecklistItemByIndex(item.index() + 1);
    
    nextItem.withIndex(item.index());
    item.withIndex(item.index() + 1);
    
    itemMap.add(item);
    itemMap.add(nextItem);
    
    this.checklist().withItems(itemMap);

    this.panel().populateData();
  }
  
  doAddChecklistItemAction(buttonData) {
    if (!buttonData) {
      return;
    }
    var itemMap = this.checklist().items();
    var item = new ChecklistItem(this.domain);
    item
      .withIndex(itemMap.size())
      .withTypeString()
      .done();
    itemMap.add(item);
    this.checklist().withItems(itemMap);
    this.panel().populateData(true);
  }
  
  doRemoveChecklistItemAction(buttonData) {
    if (!buttonData) {
      return;
    }
    var objectId = buttonData['id'];
    var itemMap = this.checklist().items();
    var item = itemMap.getChecklistItem(objectId);
    itemMap.remove(item);
    
    var sorted = itemMap.sortByIndex(); 
    for (var i = 0; i < sorted.length; i++) {
      item = sorted[i];
      item.withIndex(i);
      itemMap.add(item);
    }
    
    this.checklist().withItems(itemMap);

    this.panel().populateData();
  }
  
  doUpOptionAction(buttonData) {
    if (!buttonData) {
      return;
    }
    
  }
  
  doDownOptionAction(buttonData) {
    if (!buttonData) {
      return;
    }
  }
  
  doAddOptionAction(buttonData) {
    var itemId = buttonData['parentId'];
    var items = this.checklist().items();
    var item = items.getChecklistItem(itemId).find();
    var optionMap = item.options();
    var option = new ChecklistItemOption(this.domain());
    option
      .withIndex(optionMap.size())
      .withCategoryGood();
    optionMap.add(option);
    item.withOptions(optionMap);
    items.add(item)
    this.checklist().withItems(items)
    this.panel().populateData();
  }
  
  doRemoveOptionAction(buttonData) {
    if (!buttonData) {
      return;
    }
    
    var itemId = buttonData['parentId'];
    var itemMap = this.checklist().items();
    var item = itemMap.getChecklistItem(itemId)
    var optionMap = item.options();
    
    var optionId = buttonData['id'];
    var option = optionMap.getCheckListItemOption(optionId);
    optionMap.remove(option);
    
    var sorted = optionMap.sortByIndex(); 
    for (var i = 0; i < sorted.length; i++) {
      option = sorted[i];
      option.withIndex(i);
      optionMap.add(option);
    }
    
    item.withOptions(optionMap);
    this.panel().populateData();
  }
  
  tableData() {
    return this._tableData;
  }
  
  isSaving() {
    return this._isSaving;
  }
  
  areRequirementsMet() {
    var met = true;
    var checklist = this.checklist();
    if (StringUtils.isEmpty(checklist.name())) {
      met = false;
    }
    var items = checklist.items();
    var itemKeys = items.keys();
    for (var i = 0; i < itemKeys.length; i++) {
      var item = items.getChecklistItem(itemKeys[i]);
      if (StringUtils.isEmpty(item.name())) {
        met = false;
        break;
      }
      var options = item.options();
      var optionKeys = options.keys();
      for (var j = 0; j < optionKeys.length; j++) {
        var option = options.getCheckListItemOption(optionKeys[j]);
        if (StringUtils.isEmpty(option.name())) {
          met = false;
          break;
        }
      }
    }
    return met;
  }
  
  showMissingRequirements() {
    return this._showMissingRequirements;
  }
  
  doSaveAction() {
    if (!this.areRequirementsMet()) {
      this.domain().logger().info("Requirements not met!");
      this._showMissingRequirements = true;
      return;
    }
    
    this._isSaving = true;
    this.panel().showSavingDialog = true;
    this.panel().populateData();
    
    var event = this.domain()
        .events()
        .checklists().save(this.checklist());
    event.send(this.doSaveActionListener, this);
    this.domain().logger().info("{0}", [this.checklist().toString()]);
  }
  
  doSaveActionListener(response, context) {
    if (context) {
      context._isSaving = false;
      context.panel().showSavingDialog = false;
      if (response.hasError()) {
        context._error = response.error;
        context.panel().populateData();
      } else {
        context.panel().$router.replace({ 
          name: context.panel().$route.query.redirectTo || ConstUtils.ROUTES.CHECKLIST.LIST 
        });
      }
    }
  }
  
  doCancelAction() {
    this.panel().$router.replace({ 
      name: this.panel().$route.query.redirectTo || ConstUtils.ROUTES.CHECKLIST.LIST 
    });
  }
}







